document.addEventListener("DOMContentLoaded", () => {
    document.querySelector('body').addEventListener('keyup', escapeAllThings);
});

var escapeAllThings = (event) => {
    if (event.key === "Escape") {
        try {
            // this got a little convoluted because hitting escape is triggering the catgory opens
            //
            // if any internal categories are opened, escape press only closes those first
            // if non are open (such as next press of escape) full menu is closed
            let dropdownsOpen = document.querySelectorAll('.dropdown.yamm-fw.open');
            let fullYammsOpen = document.querySelectorAll('.full.yamm.visible');
            console.log(event.target);
            if (fullYammsOpen.length > 0 && ( (
                        dropdownsOpen.length == 0 && 
                        !event.target.classList.contains('dropdown-toggle')
                        )
                    || (
                        dropdownsOpen.length > 0 &&
                        event.target.classList.contains('dropdown-toggle')
                        )
                    ) 
                ) {
                fullYammsOpen.forEach(yamm => yamm.classList.remove('visible'));
                let mobileButton = document.querySelector('#menu-nav');
                mobileButton.classList.remove('mobile-active');
                mobileButton.classList.remove('open');
            }

            if (dropdownsOpen.length > 0) {
                dropdownsOpen.forEach(dropdown => dropdown.classList.remove('open'));
            }
        } catch(e) {
            // silent erroring
        }

        try {
            let expandeds = document.querySelectorAll('#expand_section');
            expandeds.forEach(expanded => expanded.style.display = "none");
        } catch(e) {
            // silent erroring
        }
        
    }
}