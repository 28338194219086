require("./components/js/_polyfills");
require("core-js/fn/symbol/iterator.js");
require("core-js/es6/symbol.js");
require("./components/js/bootstrap/2tooltip");
require("./components/js/bootstrap/affix");
require("./components/js/bootstrap/alert");
require("./components/js/bootstrap/button");
require("./components/js/bootstrap/collapse");
require("./components/js/bootstrap/dropdown");
require("./components/js/bootstrap/modal");
require("./components/js/bootstrap/popover");
require("./components/js/bootstrap/scrollspy");
require("./components/js/bootstrap/tab");
require("./components/js/bootstrap/transition");
require("./components/js/application");
require("./components/js/block-grid-panel-match-height");
require("./components/js/cope-cms-integration");
require("./components/js/cope-feeds");
require("./components/js/cope-ai-combine");
require("./components/js/css3-mediaqueries");
require("./components/js/mobilenav");
require("./components/js/mus-alert");
require("./components/js/relevel-cards");
require("./components/js/escape-closer");

//require("./scripts/script.min");