function relevelCards() {
    $('div[class*=block-grid-] .msu-card-content').height('');
    $('div[class*=block-grid-] .msu-card-header').height('');

    var block_grids = $('.block-grid-item').parent();

    block_grids.each(function() {
       var tallest_body = -1;
       var tallest_header = -1;
       var block_grid_items = $(this).children();

        block_grid_items.each(function() { // Find tallest card
            current_height_body = $(this).children().children('.msu-card-content').height();
            current_height_header = $(this).children().children().children('.msu-card-header').height();

            tallest_body = tallest_body > current_height_body ? tallest_body : current_height_body;
            tallest_header = tallest_header > current_height_header ? tallest_header : current_height_header;
        });

        block_grid_items.each(function() { // Make all cards the tallest height
            $(this).children().children('.msu-card-content').height(tallest_body);
            $(this).children().children().children('.msu-card-header').height(tallest_header);
        });

        // $('div[class*=block-grid-] .panel .panel-body').height(tallest_body); 
    });
}

$(window).on('resize', function() {
	relevelCards();
});

$(window).on('load', function() {
	relevelCards();
});