var alertURL;
var pollingID;
var pollEvent = new CustomEvent('musAlertPoll');
var alertUpdateEvent = new CustomEvent('musAlertUpdate');

/* alert styles:
see: _mus-alert.scss
*/

function fixYAMM() {
    var alertHeight = $('#mus-topalert').height();
    //make room if the megamenu is present by moving its affixed position down
    
    alertHeight = alertHeight < 4 ? 0 : alertHeight;
    //$('#stick2').css('top',alertHeight); //nope have to make it a css rule instead
    if (!document.querySelector('style#yamm-control-height')) {
        var styleElement = document.querySelector('body>style#yamm-control-height');
        if (!styleElement) {
            styleElement = document.body.insertAdjacentElement('beforeend',
                document.createElement('style'));
            styleElement.id = 'yamm-control-height';
        }
        /** @type {CSSStyleSheet} styleSheet */
        var styleSheet = styleElement.sheet;
        if (styleSheet.rules.length > 0) {
            styleSheet.removeRule(0);
        }
        styleSheet.insertRule('#stick2.affix { '
        + 'top: ' + alertHeight + 'px;'
        + '}'
        , 0);

    }

}

function affixAlert() {
    //DEPENDENCY: relies on the bootstrap affix plugin used by the YAMM
    var alertHeight = $('#mus-topalert').height();

    fixYAMM();
    //affix the current alert: does nothing if already affixed. Then force re-set the activation height
    $('#mus-topalert').affix({
        offset: {
        top: alertHeight
        }
    }).data('bs.affix').options.offset.top = alertHeight;
}

function pollDelay() {
    pollingID = window.setTimeout(pollFire, 50000);
}

function pollFire() {
    window.dispatchEvent(pollEvent);
}

function pollCheck() {
    $.ajax({
        url: alertURL,
        cache: false,
        dataType: "text"
    }).done(function (data) {
        //check if the return has any content
        if (/[^\s]/.test(data)) {

            //check if the return has actual content
            var alertNode = document.getElementById('mus-topalert');
            var testNode = alertNode.cloneNode(false);
            $(testNode).html(data);
            var testText = $(testNode).text();
            if (/[^\s]/.test(testText)) {
                //if alert div is not visible, make it
                if (!$(alertNode).is(":visible")) {
                    $(alertNode).show();
                }
                //check if content differs from div content
                if (!($(alertNode).html() == $(testNode).html())) {
                    $(alertNode).html($(testNode).html());  //don't use replaceWith()
                    dispatchEvent(alertUpdateEvent);
                }
            } else {
                //if we're no longer getting actual content, make sure we clear and hide any existing divs
                $('body>#mus-topalert').html('').hide();
                fixYAMM();
            }
        }  else {
            //if we're no longer getting actual content, make sure we clear and hide any existing divs
            $('body>#mus-topalert').html('').hide();
            fixYAMM();
        } 
    }).fail(function (data) {
        //TODO
    }).always(function (data) {
        pollDelay();
    })
}

$(document).ready(function () {

    //load the URL: this makes it possible to have different URLs in different templates.
    alertURL = $('body>#mus-topalert').data('alert-url');
    //check that alertURL is a valid URL
    if (/^https?:\/\//.test(alertURL)) {
    window.addEventListener('musAlertPoll', function (e) {
        pollCheck();
    }, false);
    window.addEventListener('musAlertUpdate', function (e) {
        affixAlert();
    }, false);
    pollCheck();
    }
})