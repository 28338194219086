/* * * * * * * * * * * * * * * * * * * * * * * * * * * * */
/*
  * Helpers: modularize these out to avoid redundancies
  * [TODO] modularize out of here
*/

/*
  * Element.closest() polyfill
  * https://developer.mozilla.org/en-US/docs/Web/API/Element/closest#Polyfill
*/
if (!Element.prototype.closest) {
  if (!Element.prototype.matches) {
    Element.prototype.matches = Element.prototype.msMatchesSelector || Element.prototype.webkitMatchesSelector;
  }
  Element.prototype.closest = function (s) {
    var el = this;
    var ancestor = this;
    if (!document.documentElement.contains(el)) return null;
    do {
      if (ancestor.matches(s)) return ancestor;
      ancestor = ancestor.parentElement;
    } while (ancestor !== null);
    return null;
  };
}

/*
  * check for options object being supported 
  *
*/
var passiveSupported = false;
try {
  var opts = Object.defineProperty({}, 'passive', {
    get: function () {
      passiveSupported = true;
    }
  });
  window.addEventListener("testPassive", null, opts);
  window.removeEventListener("testPassive", null, opts);
} catch (e) { console.log(e) }

/*
  * Run the callback handler when we're sure the DOM is loaded
  *
*/
export function docReady(handler) {

  var newHandler = function () {
    // injects an event removal on the callback handler
    handler();
    if (document.addEventListener) {
      document.removeEventListener('DOMContentLoaded', newHandler);
    }
  };
  if (document.readyState != 'loading') {
    // already loaded
    newHandler();
  } else if (document.addEventListener) {
    document.addEventListener('DOMContentLoaded', newHandler);
  } else {
    // mild backwards compat
    document.attachEvent('onreadystatechange', function () {
      if (document.readyState == 'complete') {
        newHandler();
      }
    });
  }
}

/*
* [END TODO]
*/
/* * * * * * * * * * * * * * * * * * * * * * * * * * * * */

/*
  * Record the current page state: find all feeds and search boxes
  *
*/
function stateSnapShot(thisState) {
  var historyCollection = {};
  var feedsCOPE = document.querySelectorAll('div.cope-feed-cms-endpoint');
  var searchesCOPE = document.querySelectorAll('.cope-feed-search');

  for (var feedNum = 0; feedNum < feedsCOPE.length; feedNum++) {
    var feed = feedsCOPE[feedNum];
    var feedObject = new Object();
    feedObject.targetURL = feed.getAttribute('data-targeturl');
    feedObject.currentPage = feed.getAttribute('data-targetpage');
    historyCollection[feed.id] = feedObject;
  }

  for (var searchNum = 0; searchNum < searchesCOPE.length; searchNum++) {
    var search = searchesCOPE[searchNum];
    var feedID = search.getAttribute('data-cope-feed-id');
    historyCollection[feedID].searchString = search.querySelector('input[name="find"]').value;
  }

  try {
  // If we didn't call this with a replacement indicator, push a new state
  if (typeof thisState === 'undefined' || !thisState) {
    history.pushState(historyCollection, ''); // title currently ignored, use empty string
  } else {
    history.replaceState(historyCollection, '');
  }
} catch (e) { console.error(e); }

};

/*
  * Event messaging for returning from a user initiated feed action that
  * requires a stateSnapShot for its result, then remove the event handler.
*/
var pageFeedEvent = new CustomEvent('pageActionDoneCOPE');
var pageActionDoneCOPE = function () {
  stateSnapShot();
  document.removeEventListener('pageActionDoneCOPE', pageActionDoneCOPE);
};

/*
  * Hook for whatever needs to do things to feeds once they're ready
  *
*/
var feedReadyEvent = new CustomEvent('feedReadyCOPE', {
  bubbles: true
});

/*
  * load/reload individual feed based on event tied to each feed
  * fire a pageActionDoneCOPE event when complete
*/
var feedEvent = new CustomEvent('feedActionCOPE');
var feedActionCOPE = function () {
  console.log('feed event');
  var targetDiv = this;
  var targetID = targetDiv.id;
  var searchDiv = document.querySelector('.cope-feed-search[data-cope-feed-id="' + targetID + '"]');
  var totalURL = targetDiv.getAttribute('data-targetURL');
  var newPageString = targetDiv.getAttribute('data-targetpage');

  if (typeof (searchDiv === 'element') && searchDiv.offsetParent === null) {
    //search is not visible, nothing to do
  } else {
    //search is visible, inject related search form data into the feed call
    /* Note that FormData does not have adequate cross browser support right now, nor does for...of
    var searchForm = new FormData(searchDiv.querySelector('form'));
    for (var kvPair of searchForm) {
      totalURL = totalURL + '&' + kvPair[0] + '=' + kvPair[1];
    }
    */

    //[TODO] this will need further refinement for handling the faux dropdown menus
    var searchElems = searchDiv.querySelectorAll('form *[name]');
    for (var elemNum = 0; elemNum < searchElems.length; elemNum++) {
      var thisElem = searchElems[elemNum];
      totalURL = totalURL + '&'
        + thisElem.getAttribute('name') + '=' + thisElem.value;
    }
  }

  if (newPageString) {
    totalURL = totalURL + '&page=' + newPageString;
  }

  // if we got here without a history state, serialize state on current history
  if (!window.history.state) {
    console.log('history snapshot')
    stateSnapShot(true);
  }

  // make the feed request
  var httpRequest = new XMLHttpRequest();
  
  httpRequest.onreadystatechange = function () {
    if (httpRequest.readyState === XMLHttpRequest.DONE) {
      if (httpRequest.status == 200) {
        targetDiv.innerHTML = '';
        if (httpRequest.responseType == 'document') {
          try {
            console.log('cope-doc-return');
            console.log(httpRequest.response);
            for (let script of httpRequest.response.querySelectorAll('script')) {
              let newScript = document.createElement('script');
              newScript.innerHTML = script.innerHTML;
              targetDiv.appendChild(newScript);
            }
            for (let element of httpRequest.response.querySelectorAll('body > *')) {
              targetDiv.appendChild(element);
            }
          } catch (e) { console.error(e); }
        } else {
          console.log('cope-text-return');
          targetDiv.innerHTML = httpRequest.responseText;
        }
        
        //var loadedScripts = targetDiv.querySelectorAll('script');

        
        var loadedPageString = '';
        var pagination = targetDiv.querySelector('.autobucket-pagination');
        if (pagination) {
          loadedPageString = pagination.getAttribute('data-page');
          bottomPaginate(targetDiv);
        }
        targetDiv.setAttribute('data-targetpage', loadedPageString);
        
        document.dispatchEvent(pageFeedEvent);
        targetDiv.dispatchEvent(feedReadyEvent);
        try {
          // TODO: this should be reversed so that instead the page slick code reacts to the feedReadyEvent
          slickInit();
        } catch (e) { console.error(e); }
      }
    }
  }

  httpRequest.open('GET', totalURL);
  httpRequest.responseType = 'document';
  httpRequest.send();

};


/*
  * reload from history into the page elements, fire on elements needing to AJAX
  *
*/
function stateSnapLoad() {
  var historyState = history.state;
  console.log('state snap load');
  // null history state should only occur on new page load

  var feedsCOPE = document.querySelectorAll('div.cope-feed-cms-endpoint');
  var searchesCOPE = document.querySelectorAll('.cope-feed-search');
  for (var searchNum = 0; searchNum < searchesCOPE.length; searchNum++) {
    try {
      var search = searchesCOPE[searchNum];
      search.value = '';
    } catch (e) { console.log(e); console.log(search); }
  }

  for (var feedNum = 0; feedNum < feedsCOPE.length; feedNum++) {
    try {
      var feed = feedsCOPE[feedNum];
      var feedID = feed.id;
      if (historyState && historyState.hasOwnProperty(feedID)) {
        var targetID = feedID;
        var targetHistory = historyState[targetID];
        var targetDiv = document.getElementById(targetID);
        if (targetDiv) {
          var searchDiv = document.querySelector('.cope-feed-search[data-cope-feed-id="' + targetID + '"]');
          var searchInput;
          if (searchDiv) {
            searchInput = searchDiv.querySelector('input[name="find"]');
          }
          targetDiv.setAttribute('data-targeturl', targetHistory.targetURL);
          targetDiv.setAttribute('data-targetpage', targetHistory.currentPage);
          if (typeof targetHistory.searchString !== 'undefined') {
            searchInput.value = targetHistory.searchString;
          }
        }
      } else {
        //feed.setAttribute('data-targetpage', '0');
      }
      feed.dispatchEvent(feedEvent);
    } catch (e) { console.error(e); console.log(feed); }
  }
  /*  
    {
      for (var target in historyState) {
        targetID = target;
        var targetHistory = historyState[target];
        var targetDiv = document.getElementById(targetID);
        if (targetDiv) {
          var searchDiv = document.querySelector('.cope-feed-search[data-cope-feed-id="' + targetID + '"]');
          var searchInput;
          if (searchDiv) {
            searchInput = searchDiv.querySelector('input[name="find"]');
          }
          targetDiv.setAttribute('data-targeturl', targetHistory.targetURL);
          targetDiv.setAttribute('data-targetpage', targetHistory.currentPage);
          if (typeof targetHistory.searchString !== 'undefined') {
            searchInput.value = targetHistory.searchString;
          }
          //targetDiv.dispatchEvent(feedEvent);
        }
      }
    }
    */
};



/*
  * this copies pagination to the bottom of the feed if the feed height is > viewport
  *
*/
function bottomPaginate(element) {
  if (element.clientHeight > (window.innerHeight - 100)) {
    var pagination = element.querySelector('.autobucket-pagination');
    var newPagination = pagination.cloneNode(true);
    element.appendChild(newPagination);
  }
};


/*
  * this is for reacting to pagination on bubbling
  *
*/
var feedClickEvent = function (event) {
  if (event.target.closest('.autobucket-pagination .paginater')) {
    var targetDiv = event.target.closest('div.cope-feed-cms-endpoint');
    if (event.target.closest('.page-back')) {
      var targetPage = event.target.closest('.page-back').getAttribute('data-page');
      targetPage = targetPage < 0 ? 0 : targetPage;
      targetDiv.setAttribute('data-targetpage', targetPage);
    } else if (event.target.closest('.page-next')) {
      var targetPage = event.target.closest('.page-next').getAttribute('data-page');
      targetDiv.setAttribute('data-targetpage', targetPage);
    }
    document.addEventListener('pageActionDoneCOPE', pageActionDoneCOPE);
    if (targetDiv.getClientRects()[0].y < 200) {
      targetDiv.scrollIntoView();
      scrollOutFromUnder(targetDiv);
    }
    targetDiv.dispatchEvent(feedEvent);
  }
};

/*
  * this scrolls an element until it's no longer covered by fixed/etc top elements.
  *
*/
function scrollOutFromUnder(element) {
  // use a timer to account for any animations from the scroll to top
  var timerID = window.setTimeout((function (element) {
    var boundingBox = element.getBoundingClientRect();
    var left = boundingBox.left;
    var top = boundingBox.top;
    var topElement = document.elementFromPoint(left, top)
    var count = 0; // sanity check
    try {
      while (element.closest('.cope-feed-cms-endpoint') !== topElement && count < 10) {
        var scrollHeight = topElement.offsetHeight + 5;
        window.scrollBy(0, 0 - scrollHeight);
        top = top + scrollHeight;
        topElement = document.elementFromPoint(left, top);
        count++;
      }
    } catch (e) {
      console.error(e);
    }
  })(element), 200);
};

/*
  * this is for reacting to search on bubbling
  *
*/
var searchClickEvent = function (event) {
  var searchDiv = event.target.closest('.cope-feed-search');
  var targetDiv = document.getElementById(searchDiv.getAttribute('data-cope-feed-id'));
  if (event.target.closest('.search-button')) {
    targetDiv.setAttribute('data-targetpage', '0');
    document.addEventListener('pageActionDoneCOPE', pageActionDoneCOPE);
    targetDiv.dispatchEvent(feedEvent);
  } else if (event.target.closest('.clear-button')) {
    var searchInput = searchDiv.querySelector('input[name="find"]');
    searchInput.value = '';
    targetDiv.setAttribute('data-targetpage', '0');
    document.addEventListener('pageActionDoneCOPE', pageActionDoneCOPE);
    targetDiv.dispatchEvent(feedEvent);
  }
};

/*
  * browser back event handler
  *
*/
var popStateHandler = function (event) {
  var historyState = event.state;
  stateSnapLoad();
};


// shim for if onpopstate not present (older IE)
if (window.onpopstate !== undefined) {
  window.onpopstate = popStateHandler;
} else {
  window.onhashchange = popStateHandler;
}

/*
  * Initialize the page on load: attach event handlers, act on state
  *
*/
var initOnLoad = function () {
  var historyState = history.state;
  console.log('history state');
  console.log(history.state);
  var feedsCOPE = document.querySelectorAll('div.cope-feed-cms-endpoint');
  var searchesCOPE = document.querySelectorAll('.cope-feed-search');
  console.log(feedsCOPE);
  console.log(searchesCOPE);
  for (var searchNum = 0; searchNum < searchesCOPE.length; searchNum++) {
    try {
      var search = searchesCOPE[searchNum];
      search.addEventListener('click', function (e) { searchClickEvent(e); }, passiveSupported
        ? { passive: true } : false);
      console.log('searchevents');
    } catch (e) { console.log(e); }
  }
  for (var feedNum = 0; feedNum < feedsCOPE.length; feedNum++) {
    try {
      var feed = feedsCOPE[feedNum];
      feed.addEventListener('feedActionCOPE', feedActionCOPE);
      console.log('event attached');
      feed.addEventListener('click', function (e) { feedClickEvent(e); }, passiveSupported
        ? { passive: true } : false);
      console.log('feedevents');
    } catch (e) { console.log(e); console.log(feed); }
  }

  stateSnapLoad();
};

docReady(initOnLoad);
