//= require bootstrap
$(document).ready(function() {
	$("#expandme").click(function() {
		$('#expand_section').toggle();
	}); 

	$('#expand_section ul').find((':has(div)')).parent().addClass('nolink');

	window.prettyPrint && prettyPrint();
	$(document).on('click', '.yamm .dropdown-menu', function(e) {
		e.stopPropagation();
	});

	$('#expand_section ul>li:has(ul)').click(function(event) {
		//alert("onclick="+$(this).attr('class'));
		$newul = $(this).find("ul:first");
		if ($newul.css("display") == "none") {
			$newul = $(this).find("ul:first");
			$newul.parent().removeClass("drop");
			$newul.parent().addClass("opendrop");
			$newul.show("fast");
			$newul.parent().removeClass("togopen");
		} else {
			$newul = $(this).find("ul:first");
			$newul.parent().removeClass("opendrop");
			$newul.parent().addClass("drop");
			$newul.hide("fast");
			$newul.parent().removeClass("togopen");
		}
		event.stopPropagation();
	});
	($('nav li').has('ul')).each(function() {
		$(this).addClass("drop");
		$(".exnav>li:has(ul)>a").css("display", "block");
		$(this).show("fast");
	});

	$("#menu-search").click(function() {
		$("#menu-nav").removeClass('mobile-active');
		$(this).toggleClass('mobile-active');
		$("#menu-search-open").toggle();
	});

	$("#yamm-placeholder").each(function() {
		$("#menu-nav").click(function() {
			$("#menu-search").removeClass('mobile-active');
			$(this).toggleClass('mobile-active');
			$("#menu-search-open").hide();
			$("#navbar-collapse-1 > ul > li").not(":has('b')").addClass("single");
			// no plus toggle on rwd
		});

		$('#menu-nav').show();
	});

	// Fix for IE8 window resize
	var lastWindowHeight = $(window).height();
	var lastWindowWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
	var currentW = 'small';

	if (lastWindowWidth >= 768) {
		currentW = 'large';
	}

	$(window).resize(function() {

		//confirm window was actually resized
		if ($(window).height() != lastWindowHeight || Math.max(document.documentElement.clientWidth, window.innerWidth || 0) != lastWindowWidth) {

			lastWindowHeight = $(window).height();
			lastWindowWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);

			//call my function
			if ((lastWindowWidth < 768) && (currentW == 'large')) {
				currentW = 'small';
				//console.log('CHANGE VP: ' + currentW);
				smallWindow();
			}
			if ((lastWindowWidth >= 768) && (currentW == 'small')) {
				currentW = 'large';
				//console.log('CHANGE VP: ' + currentW);

				$("#expand_section").show();
				$('#left').insertBefore('#rightpane');
				$('#yamm-placeholder').append($('.yamm'));

			}
		}
	});

	if (!($('div').hasClass('feature-contact')) && $("#rightpane").hasClass("col-sm-12")) {
		$("<div id='footer-contact' class='wrapper'><div class='container'><div class='row'></div></div></div>").insertBefore("#footer");
		$('#footer-contact .row').append($('#leftoptions')).append($('#contact'));
	}

	if ((Math.max(document.documentElement.clientWidth, window.innerWidth || 0)) < 768) {
		//console.log('width: ' + (Math.max(document.documentElement.clientWidth, window.innerWidth || 0)));
		smallWindow();
		$("#expand_section").hide();
	}

	function smallWindow() {
		$('#left').insertAfter('#header');
		$('#left').addClass('mobilemenu');
		$("#expand_section").hide();
		$("#menu-search-open").hide();

		$("#yamm-placeholder").each(function() {
			$('.yamm').insertAfter('#header').removeClass('visible');
			$('#menu-nav').show().removeClass('mobile-active').removeClass('open');
		});

	}

	//Direct Edit swap code to make ob work in dynamic templates:
	$('#ouc-direct-edit-wrapper a').attr('href', $('#ouc-ob-fill a').attr('href'));
	$('#ouc-ob-fill').remove();
	$('#ouc-direct-edit-wrapper').contents().unwrap();
	$('footer div.maxwidth').append($('#de'));
});