import {docReady} from './cope-feeds'
/* to cause this combined sorting to execute, set the JS variable combineDirectory = true in the <head> of the CMS page */
var combineDirectory = window.combineDirectory ? window.combineDirectory : false;

function sortableName(directoryItem) {
	if (directoryItem.find(".ai-faculty-name").data("sortable")) {
		return directoryItem.find(".ai-faculty-name").data("sortable");
	} else {
		var rawString = directoryItem.find(".ai-faculty-name").text().trim();
		var trimString = rawString.trim().replace(/\s\s+/g, ' ');
		console.log(trimString.split(" "));
		return trimString.split(" ").reverse().join("");
	}

}

var combineAICOPE = function () {
	var nonAIlistLi = $(".faculty-directory:not([id])");
	nonAIlistLi.each(function () {
		var name = sortableName($(this));
		var nonAIItem = $(this);
		$(".faculty-directory[id]").each(function () {
			var compareName = sortableName($(this));
			if (name < compareName) {
				$(this).before(nonAIItem);
				return false;
			}
		});
	});
};

docReady(function () {
  if (combineDirectory == true) {
    document.addEventListener('feedReadyCOPE', combineAICOPE);
  }
});
